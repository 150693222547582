<template>
  <div class="side-page">
    <div
      class="side-page__btn-wrapp"
      @click.stop="toggleSideBar"
    >
      <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'side-page__btn-nav'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 14.667C0 15.403.597 16 1.333 16h15.62a1.333 1.333 0 000-2.667H1.332C.597 13.333 0 13.93 0 14.667zM0 8c0 .736.597 1.333 1.333 1.333h21.334a1.333 1.333 0 000-2.666H1.333C.597 6.667 0 7.264 0 8zm1.333-8a1.333 1.333 0 000 2.667h21.334a1.333 1.333 0 000-2.667H1.333z" fill="#FFF"/></svg>
    </div>
    <Header />

    <div
      :class="[
        'side-page__asside',
        { 'side-page__asside--shown' : isSidebarShown }
      ]"
      v-click-outside="onClickOutside"
    >

      <vue-custom-scrollbar
        class="custom-scrollbar"
        :settings="{ wheelPropagation: false }"
      >
        <router-view
          name="sidebar"
        />

      </vue-custom-scrollbar>
    </div>

    <div class="side-page__main">
      <router-view :key="routerViewKey"/>
    </div>

  </div>
</template>

<script>

import Header from '@component/Part/Header';

export default {
  name: "LayoutWithSidebar",
  components: {
    Header
  },
  data() {
    return {
      isSidebarShown: false,
      routerViewKey: Math.random()
    };
  },
  watch: {
    '$route'() {
      this.routerViewKey = Math.random();
    }
  },
  methods: {
    toggleSideBar() {
      setTimeout(() => {
        this.isSidebarShown = !this.isSidebarShown;
      }, 0);
    },
    onClickOutside() {
      if (this.isSidebarShown) {
        this.isSidebarShown = !this.isSidebarShown;
      }
    },
  }
};
</script>

<style lang="scss" scoped>

// side-page
.side-page {
  display: flex;
  min-height: 100vh;
  background-color: #F2F2F6;
  .side-page__btn-wrapp {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    cursor: pointer;
    padding: 15px;
  }
  .side-page__asside {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    height: 100%;
    padding-top: 52px;
    background-color: #FFFFFF;
    box-shadow: -3px 4px 12px rgba(0, 0, 0, 0.25);
    transform: translateX(-100%);
    transition: all .2s ease-in-out;
    z-index: 80;
    .custom-scrollbar {
      max-height: calc(100vh - 52px);
    }
    &--shown {
      transform: translateX(0);
    }
  }
  .side-page__main {
    width: 100%;
  }
  @include respond-to('sm') {
    .side-page__asside {
      width: 375px;
    }
  }
  @include respond-to('xl') {
    .side-page__asside {
      transform: translateX(0);
    }
    .side-page__main {
      padding-left: 375px;
    }
    .side-page__btn-wrapp {
      display: none;
    }
  }
}

</style>
